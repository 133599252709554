<template>
  <v-combobox
    :value="input.label"
    :hint="'Input type: ' + inputTypeLabel"
    :items="fields"
    :prepend-icon="firstInRow ? 'fal fa-plus-circle green--text' : ''"
    item-text="label"
    prepend-inner-icon="fal fa-pencil"
    append-icon="fal fa-times-circle red--text"
    append-outer-icon="fal fa-plus-circle green--text"
    return-object
    persistent-hint
    outlined
    @change="(val) => $emit('change', val)"
    @click:prepend="$emit('addBefore')"
    @click:prepend-inner="$emit('edit')"
    @click:append="$emit('remove')"
    @click:append-outer="$emit('addAfter')">
    <template v-slot:label>
      <div v-html="htmlLabel"></div>
    </template>
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title>{{ item.label }}</v-list-item-title>
        <v-list-item-subtitle>{{ item.input }}</v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-combobox>
</template>
<script>
import { computed, ref } from '@vue/composition-api'
export default {
  name: 'form-editor-input',
  props: {
    input: {
      type: Object,
      required: true
    },
    inputList: Array,
    firstInRow: Boolean,
    uniqueEnabled: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { root, emit }) {
    const asteriskOnRequired = computed(() => root.$store.state.forms.formSettings.asteriskOnRequired)
    const fields = ref([
      { label: 'Banner ID', input: 'text', md: 4, lg: 4 },
      { label: 'First Name', input: 'text', md: 4, lg: 4 },
      { label: 'Last Name', input: 'text', md: 4, lg: 4 },
      { label: 'Middle Name', input: 'text', md: 4, lg: 4 },
      { label: 'Preferred Name', input: 'text', md: 4, lg: 4 },
      { label: 'Maiden Name', input: 'text', md: 4, lg: 4 },
      { label: 'Birth Date', input: 'date', md: 4, lg: 4 },
      {
        label: 'Address Block',
        input: 'repeatable-block',
        inputs: [
          { label: 'Address Type', input: 'select', md: 6, lg: 6, options: '5f9721916835980f0049b717' },
          { label: 'Street Address', input: 'text', md: 6, lg: 6 },
          { label: 'City', input: 'text', md: 4, lg: 4 },
          { label: 'State', input: 'select', options: '5f9721926835980f0049b9d1', md: 4, lg: 4 },
          { label: 'Zip', input: 'text', md: 4, lg: 4 }
        ],
        md: 12,
        lg: 12,
        settings: {
          showLabel: false,
          addButton: true,
          addButtonText: '',
          min: 1,
          removable: true
        }
      },
      { label: 'Address Type', input: 'select', md: 4, lg: 4, options: '5f9721916835980f0049b717' },
      { label: 'Street Address', input: 'text', md: 12, lg: 12 },
      { label: 'City', input: 'text', md: 4, lg: 4 },
      { label: 'State', input: 'select', options: '5f9721926835980f0049b9d1', md: 4, lg: 4 },
      { label: 'Zip', input: 'text', md: 4, lg: 4 },
      { label: 'Nation', input: 'select', options: '5f9721926835980f0049b8d1', md: 4, lg: 4 },
      {
        label: 'Email Block',
        input: 'repeatable-block',
        inputs: [
          { label: 'Email', input: 'email', md: 12, lg: 12 }
        ]
      },
      { label: 'Email', input: 'email', md: 6, lg: 6 },
      {
        label: 'Phone Block',
        input: 'repeatable-block',
        inputs: [
          { label: 'Phone', input: 'text', md: 6, lg: 6 },
          { label: 'Phone Type', input: 'select', options: '5f9721926835980f0049b9c2', md: 6, lg: 6 }
        ]
      },
      { label: 'Phone', input: 'text', md: 4, lg: 4 },
      { label: 'Phone Type', input: 'select', options: '5f9721926835980f0049b9c2', md: 4, lg: 4 },
      { label: 'Home Phone', input: 'text', md: 4, lg: 4 },
      { label: 'Cell Phone', input: 'text', md: 4, lg: 4 },
      { label: 'Office Phone', input: 'text', md: 4, lg: 4 }
    ])

    const inputTypes = computed(() => {
      const arr = [
        { text: 'Text Field', value: 'text' },
        { text: 'Email Address', value: 'email' },
        { text: 'Number', value: 'number' },
        { text: 'Multi-line Text Field', value: 'textarea' },
        { text: 'Select Box', value: 'select' },
        { text: 'Combo Box', value: 'combobox' },
        { text: 'AutoComplete', value: 'autocomplete' },
        { text: 'Radio Group', value: 'radio' },
        { text: 'Checkbox', value: 'checkbox' },
        { text: 'Switch', value: 'switch' },
        { text: 'Slider', value: 'slider' },
        { text: 'Range Slider (Dual Slider)', value: 'rangeSlider' },
        { text: 'Date Picker', value: 'datePicker' },
        { text: 'Time Picker', value: 'timePicker' },
        { text: 'File Upload', value: 'file-upload' },
        { text: 'File Upload (old)', value: 'file' },
        { text: 'Signature Pad', value: 'signature' },
        { text: 'HTML/Text Block', value: 'html-block' },
        { text: 'Church Search', value: 'church-search' },
        { text: 'Directory Search', value: 'directory-search' }
      ]
      if (props.allowRepeatable) arr.push({ text: 'Repeatable Block', value: 'repeatable-block' })
      return arr
    })
    const inputTypeLabel = computed(() => {
      if (props.input.input !== '') {
        for (let i = 0; i < inputTypes.value.length; i++) {
          if (inputTypes.value[i].value === props.input.input) return inputTypes.value[i].text
        }
      }
    })

    const htmlLabel = computed(() => {
      if ('required' in props.input && props.input.required) {
        if (asteriskOnRequired.value) return 'Input Label' + '<span class="error--text fas fa-asterisk" style="font-size:.6em;transform:translateY(-.6em);margin-left:.2em"></span>'
        else return 'Input Label (required field)'
      }
      return 'Input Label'
    })

    return {
      fields,
      inputTypes,
      inputTypeLabel,
      htmlLabel
    }
  }
}
</script>
